import { Route, Routes, useLocation } from "react-router-dom";
import { DonationAmount } from "./pages/DonationAmount";
import Payment from "./pages/Payment";
import { AppStateProvider } from "./providers/state";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
	palette: {
		primary: {
			main: "#2E5BFD",
		},
		secondary: {
			main: "#19857b",
		},
	},
	typography: {
		fontFamily: "Roboto, sans-serif",
		h5: {
			color: "#2E5BFD",
		},
		h6: {
			fontSize: "12px",
			color: "red",
		},
	},
});

function App() {
	const location = useLocation();
	return (
		<ThemeProvider theme={theme}>
			<AppStateProvider>
				<AnimatePresence mode="wait">
					<Routes location={location} key={location.pathname}>
						<Route path="/" element={<DonationAmount />} />
						<Route path="/payment" element={<Payment />} />
					</Routes>
				</AnimatePresence>
			</AppStateProvider>
		</ThemeProvider>
	);
}

export default App;
