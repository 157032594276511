import { Stripe, StripeElements } from "@stripe/stripe-js";

export async function sendPaymentIntentToServer(requestBody: {
  amount: string;
  currency: string;
  account_id: string;
  email: string;
  giftAid: boolean;
}) {
  const backendURL = process.env.REACT_APP_BACKEND_URL as string;
  return await fetch(`${backendURL}/create-payment-intent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  }).then((response) => {
    return response.json();
  });
}

export async function confirmPayment(
  stripe: Stripe,
  elements: StripeElements,
  clientSecret: string,
  redirectUrl: string,
  setErrorMessage: (value: React.SetStateAction<string>) => void
) {
  const { error, paymentIntent } = await stripe.confirmPayment({
    elements,
    clientSecret,
    confirmParams: {
      return_url: redirectUrl,
    },
    redirect: "if_required",
  });

  if (error) {
    setErrorMessage(error.message ?? "An error occurred");
    return paymentIntent;
  } else {
    switch (paymentIntent?.status) {
      case "succeeded":
        return paymentIntent;
      case "processing":
        return paymentIntent;
      case "requires_payment_method":
        setErrorMessage("Payment failed. Please try another payment method.");
        return null;
    }
    return paymentIntent;
  }
}
