import React, {
	createContext,
	useState,
	useContext,
	Dispatch,
	SetStateAction,
} from "react";

interface AppStateContextType {
	state: any; // replace 'any' with the type of your state
	setState: Dispatch<SetStateAction<any>>; // replace 'any' with the type of your state
}

const AppStateContext = createContext<AppStateContextType | undefined>(
	undefined
);

export function AppStateProvider({ children }: { children: React.ReactNode }) {
	const [state, setState] = useState<any>(null); // replace 'any' and null with your initial state

	return (
		<AppStateContext.Provider value={{ state, setState }}>
			{children}
		</AppStateContext.Provider>
	);
}

export function useAppState() {
	const context = useContext(AppStateContext);
	if (!context) {
		throw new Error("useAppState must be used within the AppStateProvider");
	}
	return context;
}
