import {
	Button,
	CardContent,
	Card,
	Grid,
	TextField,
	Typography,
	InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppState } from "../providers/state";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export function DonationAmount(): JSX.Element {
	const [amount, setAmount] = useState("1");
	const { state, setState } = useAppState();
	const queryParams = new URLSearchParams(window.location.search);

	const navigate = useNavigate();

	const handleNext = () => {
		setState({ ...state, amount });
		navigate("/payment?account_id=" + state.account_id);
	};

	const { account_id } = Object.fromEntries(queryParams.entries());

	useEffect(() => {
		setState({ ...state, account_id });
	}, [account_id]);

	return (
		<motion.div
			initial="initial"
			animate="in"
			exit="out"
			variants={{
				initial: {
					opacity: 0,
					x: 0,
				},
				in: {
					opacity: 1,
					x: 0,
				},
				out: {
					opacity: 0,
					x: "100vw",
				},
			}}
			transition={{
				type: "tween",
				ease: "anticipate",
				duration: 0.5,
			}}
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<Card
				sx={{
					borderRadius: 2,
					bgcolor: "#ffffff",
					boxShadow: "20px 20px 60px #c8c8c8, -20px -20px 60px #ffffff",
					margin: "10px",
				}}
			>
				<CardContent>
					<Grid container spacing={5} sx={{ alignItems: "center" }}>
						<Grid item xs={12} sx={{ alignItems: "center" }}>
							<Grid item xs={12}>
								<Typography align="center" variant="h5">
									Donation Amount
								</Typography>
								<Typography align="center" variant="body1">
									Please enter the amount you would like to donate
								</Typography>
							</Grid>
						</Grid>
						<Grid item container xs={12} justifyContent={"center"}>
							<TextField
								type="number"
								variant="filled"
								label="Amount"
								value={amount}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setAmount(event.target.value || "");
								}}
								inputProps={{ min: "1" }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">£</InputAdornment>
									),
								}}
								error={parseInt(amount) < 1 || amount === ""}
								helperText={
									parseInt(amount) < 1 || amount === ""
										? "Please enter a valid amount"
										: ""
								}
							/>
						</Grid>
						<Grid item container xs={12} justifyContent={"center"}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleNext()}
								disabled={
									parseInt(amount) < 1 ||
									parseInt(amount) !== parseInt(amount.toString())
								}
							>
								Next
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</motion.div>
	);
}
